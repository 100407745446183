<template>
    <div>
        <card-header title="New Custom Event" icon="fa-plus"/>

        <card-body>
            <event-form :event="newEvent"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="false" @click="submit"><i class="fas fa-plus mr-3"></i>New Custom Event</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';
    import CardFooter from '../../TIER/components/CardFooter';
    import CardList from '../../TIER/components/CardList';
    import SubheaderListItem from '../../TIER/components/SubheaderListItem';
    import CardBody from '../../TIER/components/CardBody';
    import ContainerListItem from '../../TIER/components/ContainerListItem';
    import EventForm from '../trips/EventForm';

    import {client as http} from '../../http_client';
    import {mapActions} from "vuex";

    export default {
        props: ['card', 'props'],
        components: {EventForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function () {
            return {
                newEvent: {
                    start_date: null,
                    start_time: null,
                    end_date: null,
                    end_time: null,
                    start_tz: '',
                    end_tz: '',
                    task: {},
                    day: {},
                    trip: {}
                }
            };
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadDay() {
                this.$emit('loading', true);
                http.get('/api/days/' + this.props.dayId).then(response => {
                    this.newEvent.start_date = response.data.day_on;
                    this.newEvent.end_date = response.data.day_on;
                    this.day = response.data;

                    http.get('/api/trips/' + this.day.trip_id).then(response => {
                        this.trip = response.data;
                        this.newEvent.start_tz = this.trip.trip_tz;
                        this.newEvent.end_tz = this.trip.trip_tz;
                        this.$emit('loading', false);
                    });
                });
            },
            submit() {
                this.$emit('loading', true);

                http.post('/api/registrations/' + this.props.registrationId + '/events', this.newEvent, {force: true}).then(response => {
                    this.updateTasks();
                    this.$reloadCard('registrations');
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$reloadCard('tasks');
                    this.$reloadCard('events', {dayId: this.props.dayId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.newEvent.registration_id = this.props.registrationId;
            this.newEvent.day_id = this.props.dayId;
            this.$saveAlert(true, 'Are you done creating this new event? Unsaved changes will be lost.');
            this.loadDay();
        }
    };
</script>
